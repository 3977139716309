import { useState, useEffect } from "react";
import {
	Button,
	Row,
	Col,
	Dropdown,
	Tooltip,
	message,
	Avatar,
	Modal,
	Divider,
} from "antd";
import Icon, {
	AudioMutedOutlined,
	LogoutOutlined,
	SettingOutlined,
	CopyOutlined,
	CloseOutlined,
} from "@ant-design/icons";
import { ReactComponent as BlurSvg } from "../../assets/blur.svg";
import { ReactComponent as SandClock } from "../../assets/sand-clock.svg";
import { ReactComponent as Play } from "../../assets/play.svg";
import { ReactComponent as Pause } from "../../assets/pause.svg";
import { ReactComponent as PlusFive } from "../../assets/plus-five.svg";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../assets/logo.png";

export default function Controls(props) {
	const BlurIcon = () => <BlurSvg />;
	const SandClockIcon = () => <SandClock />;
	const { loginWithRedirect } = useAuth0();

	const {
		tracks,
		setStart,
		joinedRoom,
		agoraClient,
		loggedUser,
		isBlurred,
		setIsBlurred,
		hasTimer,
		setHasTimer,
		timer,
		setTimer,
		timerRunning,
		setTimerRunning,
	} = props;
	const [trackState, setTrackState] = useState({ video: true, audio: true });
	const [isSafari, setIsSafari] = useState(false);
	const client = agoraClient();
	const [modalVisible, setModalVisible] = useState(false);

	const handleContactClick = () => {
		setModalVisible(true);
	};

	const handleCloseModal = () => {
		setModalVisible(false);
	};

	const handleEmailClick = (email) => {
		window.location.href = `mailto:${email}`;
	};

	useEffect(() => {
		if (!hasTimer) {
			setTimer(300);
			setTimerRunning(false);
		}
	}, [hasTimer]);

	useEffect(() => {
		let interval;
		if (timerRunning) {
			interval = setInterval(() => {
				setTimer((prevTimer) => {
					if (prevTimer > 0) {
						return prevTimer - 1;
					}
					return 0;
				});
			}, 1000);
		} else {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [timerRunning]);

	const playPauseTimer = () => {
		setTimerRunning(!timerRunning);
	};

	const addTime = (seconds) => {
		setTimer((prevTimer) => prevTimer + seconds);
	};

	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		return `${minutes.toString().padStart(2, "0")}:${seconds
			.toString()
			.padStart(2, "0")}`;
	};

	useEffect(() => {
		// Check if the browser is Safari
		const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(
			navigator.userAgent
		);
		setIsSafari(isSafariBrowser);
	}, []);

	const items = [
		// {
		// 	key: "3",
		// 	disabled: !loggedUser,
		// 	label: (
		// 		<a target="_blank" href="/user">
		// 			My Profile
		// 		</a>
		// 	),
		// },
		{
			key: "log",
			label: loggedUser ? (
				<>
					<Avatar size="large" src={loggedUser?.picture} alt="User Avatar" />
					<a target="_blank" href="/user">
						{" "}
						{loggedUser.givenName} {loggedUser.familyName}
					</a>
				</>
			) : (
				<Button size="large" onClick={loginWithRedirect}>
					Log in
				</Button>
			),
		},
		{
			key: "0",
			label: joinedRoom.room.link,
			icon: <CopyOutlined />,
			onClick: () => {
				message.success("Room code copied to clipboard");
				navigator.clipboard.writeText(joinedRoom.room.link);
			},
		},
		{
			key: "1",
			label: (
				<a
					target="_blank"
					href={`https://${joinedRoom.room.scheduleLink.replace(
						"https://",
						""
					)}`}
				>
					Schedule
				</a>
			),
		},
		{
			key: "2",
			label: (
				<a
					target="_blank"
					href={`https://${joinedRoom.room.cheatSheetLink.replace(
						"https://",
						""
					)}`}
				>
					Cheat Sheet
				</a>
			),
		},
		{
			key: "4",
			label: "Contact: Leads",
			onClick: () => {
				handleContactClick();
			},
		},
		{
			key: "5",
			label: (
				<a
					target="_blank"
					href="https://docs.google.com/forms/d/e/1FAIpQLSf8OQtOogPIEueh0csn7GeNJLsne9Y7Ow7czP6kKOktjVjQSg/viewform"
				>
					Contact: Good Team
				</a>
			),
		},
	];

	const mute = async (type) => {
		if (type === "audio") {
			await tracks[0].setEnabled(!trackState.audio);
			setTrackState((ps) => {
				return { ...ps, audio: !ps.audio };
			});
		} else if (type === "video") {
			await tracks[1].setEnabled(!trackState.video);
			setTrackState((ps) => {
				return { ...ps, video: !ps.video };
			});
		}
	};

	const leaveChannel = async () => {
		await client.leave();
		client.removeAllListeners();
		tracks[0].close();
		tracks[1].close();
		setStart(false);
		window.location.href = "/";
	};

	const endForAll = async () => {
		client.sendStreamMessage("END");
		await leaveChannel();
	};

	return (
		<Row gutter={16} align="middle">
			<Modal
				title="Leads"
				open={modalVisible}
				onCancel={handleCloseModal}
				footer={null}
			>
				<div>
					{joinedRoom.room.contacts?.map((contact, index) => (
						<div key={index}>{contact.userContact}</div>
					))}
				</div>
				<Divider />
				<div>
					{joinedRoom.room.leads.map((email, index) => (
						<a
							key={index}
							style={{ cursor: "pointer" }}
							onClick={() => handleEmailClick(email.userEmail)}
						>
							{email.userEmail}
						</a>
					))}
				</div>
			</Modal>

			<Col style={{ display: "flex", gap: "8px" }} span={8}>
				<Dropdown
					menu={{
						items,
					}}
					placement="top"
				>
					<Button size="large" shape="circle" icon={<SettingOutlined />} />
				</Dropdown>
				<Tooltip title={trackState.audio ? "Mute" : "Unmute"}>
					<Button
						size="large"
						onClick={() => mute("audio")}
						shape="circle"
						type={!trackState.audio ? "primary" : "default"}
						icon={<AudioMutedOutlined />}
					/>
				</Tooltip>
				{(!isSafari || joinedRoom.isUserHost) && (
					<Tooltip title={isBlurred ? "Unblur background" : "Blur background"}>
						<Button
							size="large"
							onClick={() => {
								setTimeout(() => {
									setIsBlurred(!isBlurred);
								}, 500);
							}}
							type={isBlurred ? "primary" : "default"}
							shape="circle"
							icon={<BlurIcon />}
						/>
					</Tooltip>
				)}
				{joinedRoom.isUserHost && (
					<>
						<Tooltip title={hasTimer ? "Stop/Hide timer" : "Show timer"}>
							<Button
								size="large"
								onClick={() => setHasTimer(!hasTimer)}
								type={hasTimer ? "primary" : "default"}
								shape="circle"
								icon={<SandClockIcon />}
							/>
						</Tooltip>
					</>
				)}

				{hasTimer && (
					<div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
						<p style={{ width: "75px", fontSize: "27px", margin: "0" }}>
							{formatTime(timer)}
						</p>
						{joinedRoom.isUserHost &&
							// <Button size="large" onClick={playPauseTimer}>
							// 	{timerRunning ? <PauseOutlined /> : <CaretRightOutlined />}
							// </Button>
							(timerRunning ? (
								<Icon
									onClick={playPauseTimer}
									style={{ fontSize: "27px", cursor: "pointer" }}
									component={Pause}
								/>
							) : (
								<Icon
									onClick={playPauseTimer}
									style={{ fontSize: "27px", cursor: "pointer" }}
									component={Play}
								/>
							))}
						{joinedRoom.isUserHost && (
							// <Button size="large" onClick={() => addTime(300)}>
							// 	+5
							// </Button>
							<Icon
								onClick={() => addTime(300)}
								style={{ fontSize: "27px", cursor: "pointer" }}
								component={PlusFive}
							/>
						)}
					</div>
				)}
				{/* Hide for now as there are some cases to be handled */}
				{/* PS: Billy said each user must have their camera open. */}
				{/* <Tooltip title={trackState.video ? "Stop Video" : "Start Video"}>
					<Button
						size="large"
						onClick={() => mute("video")}
						shape="circle"
						type="primary"
						danger={!trackState.video}
						icon={<VideoCameraOutlined />}
					/>
				</Tooltip> */}
			</Col>
			<Col style={{ textAlign: "center" }} span={8}>
				<img style={{ width: "200px" }} src={logo}></img>
			</Col>
			<Col
				style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
				span={8}
			>
				<Button
					type="primary"
					danger
					onClick={() => leaveChannel()}
					icon={<LogoutOutlined />}
				>
					Leave
				</Button>
				{joinedRoom.isUserHost && (
					<Button icon={<CloseOutlined />} onClick={() => endForAll()} danger>
						End for All
					</Button>
				)}
			</Col>
		</Row>
	);
}

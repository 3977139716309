import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Button, Dropdown } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import HeaderSCSS from "./Header.module.scss";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/GT---animated-logo-BLK-1.gif";

const Header = () => {
	const { logout, loginWithRedirect, isAuthenticated } = useAuth0();
	const appContext = useSelector((state) => state.app);
	const { user } = appContext.context;
	const navigate = useNavigate();

	const items = [
		{
			key: "profile",
			label: "My Profile",
			icon: <UserOutlined />,
			onClick: () => {
				navigate("/user");
			},
		},
		{
			key: "logout",
			label: "Log out",
			icon: <LogoutOutlined />,
			onClick: () => {
				logout();
			},
			danger: true,
		},
	];

	return (
		<div className={HeaderSCSS.container}>
			<img
				onClick={() => navigate("/")}
				className={HeaderSCSS.logo}
				src={logo}
			></img>
			{isAuthenticated ? (
				<div className={HeaderSCSS.userContainer}>
					<p>
						{user?.givenName} {user?.familyName}
					</p>
					<Dropdown
						menu={{
							items,
						}}
						placement="bottomRight"
					>
						<Avatar src={user?.picture} alt="User Avatar" />
					</Dropdown>
				</div>
			) : (
				<Button onClick={loginWithRedirect}>Log in</Button>
			)}
		</div>
	);
};

export default Header;

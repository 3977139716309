import React, { useEffect, useState } from "react";
import { message } from "antd";
import LandingSCSS from "./Landing.module.scss";
import { useSelector } from "react-redux";
import { Tabs, Row, Col } from "antd";
import Room from "../../components/admin/room/Room";
import Profile from "../../components/profile/Profile";
import Users from "../../components/admin/users/Users";
import { UserRole } from "../../constants/userRole";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";

const Landing = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const appContext = useSelector((state) => state.app);
	const [items, setItems] = useState([]);

	const { user } = appContext.context;

	useEffect(() => {
		if (!user) {
			return;
		}
		const newItems = [
			{
				key: "1",
				label: "Profile",
				children: <Profile />,
			},
		];
		if (
			[UserRole.ADMIN, UserRole.SUPER_ADMIN, UserRole.OWNER].includes(user.role)
		) {
			newItems.push({
				key: "2",
				label: "Rooms",
				children: <Room />,
			});
		}
		if ([UserRole.SUPER_ADMIN, UserRole.OWNER].includes(user.role)) {
			newItems.push({
				key: "3",
				label: "Users",
				children: <Users />,
			});
		}
		setItems(newItems);
	}, [user]);

	return (
		user && (
			<>
				<Header />
				<div className={LandingSCSS.container}>
					<Tabs
						tabPosition="left"
						style={{ width: "85%" }}
						defaultActiveKey="1"
						items={items}
					/>
					{contextHolder}
				</div>
				<Row className={LandingSCSS.links} justify="center">
					<Col span={12}>
						<Link to="/terms" target="_blank">
							Terms
						</Link>{" "}
						-{" "}
						<Link to="/privacy" target="_blank">
							Privacy
						</Link>{" "}
						-{" "}
						<Link to="https://www.goodte.am/trust-and-safety" target="_blank">
							Learn more
						</Link>
					</Col>
				</Row>
			</>
		)
	);
};

export default Landing;

import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import apiSlice from "./apiSlice";
import rootReducers from "./rootReducer";

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

// eslint-disable-next-line import/prefer-default-export
export function configureStore(initialState) {
  const Store = createStore(
    rootReducers,
    initialState,
    composeWithDevTools(applyMiddleware(thunk, apiSlice.middleware)),
  );
  return Store;
}

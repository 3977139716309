import React, { useState, useEffect } from "react";
import RoomrSCSS from "./Room.module.scss";
import {
	Modal,
	Form,
	Input,
	Button,
	Popconfirm,
	Space,
	Table,
	Checkbox,
	Col,
	Row,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "../../../store";
import {
	createRoom,
	updateRoom,
	deleteRoom,
	getAllRooms,
} from "../../../store/roomSlice";
import { useSelector } from "react-redux";
import { LinkOutlined } from "@ant-design/icons";
import { getAllCategories } from "../../../store/listItems";

const Room = () => {
	const dispatch = useDispatch();
	const appContext = useSelector((state) => state.app);
	const { loading, rooms } = useSelector((state) => state.room);
	const { categories } = useSelector((state) => state.listItems);
	const { accessToken } = appContext.context;
	const [form] = Form.useForm();
	const [open, setOpen] = useState(false);
	const [editingRoom, setEditingRoom] = useState(null);
	const [editingRoomIndex, setEditingRoomIndex] = useState(null);

	const [editedHostEmails, setEditedHostEmails] = useState([]);
	const [deletedHostEmails, setDeletedHostEmails] = useState([]);
	const [createdHostEmails, setCreatedHostEmails] = useState([]);

	const [editedLeadEmails, setEditedLeadEmails] = useState([]);
	const [deletedLeadEmails, setDeletedLeadEmails] = useState([]);
	const [createdLeadEmails, setCreatedLeadEmails] = useState([]);

	const [editedContacts, setEditedContacts] = useState([]);
	const [deletedContacts, setDeletedContacts] = useState([]);
	const [createdContacts, setCreatedContacts] = useState([]);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Creator",
			key: "creator",
			render: (text, record, index) => (
				<span>
					{record.creator.givenName} {record.creator.familyName}
				</span>
			),
		},
		{
			title: "Hosts",
			key: "hosts",
			render: (text, record) => (
				<>
					{record.hosts?.map((host, index) => (
						<span key={index}>
							{host}
							<br />
						</span>
					))}
				</>
			),
		},
		{
			title: "Leads",
			key: "leads",
			render: (text, record) => (
				<>
					{record.leads?.map((host, index) => (
						<span key={index}>
							{host}
							<br />
						</span>
					))}
				</>
			),
		},
		{
			title: "Code",
			dataIndex: "link",
			key: "link",
		},
		{
			title: "Cheat Sheet",
			key: "cheatSheetLink",
			render: (text, record, index) => (
				<Button
					href={`https://${record.cheatSheetLink?.replace("https://", "")}`}
					target="_blank"
					type="link"
					icon={<LinkOutlined />}
				>
					Link
				</Button>
			),
		},
		{
			title: "Schedule",
			key: "scheduleLink",
			render: (text, record, index) => (
				<Button
					href={`https://${record.scheduleLink?.replace("https://", "")}`}
					target="_blank"
					type="link"
					icon={<LinkOutlined />}
				>
					Link
				</Button>
			),
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record, index) => (
				<Space>
					<Button onClick={() => showModal(record, index)} type="link">
						Edit
					</Button>
					|
					<Popconfirm
						title="Delete the room"
						description="Are you sure to delete this room?"
						okText="Yes"
						cancelText="No"
						onConfirm={() => handleDelete(record.id)}
					>
						<Button type="link" danger>
							Delete
						</Button>
					</Popconfirm>
				</Space>
			),
		},
	];

	useEffect(() => {
		dispatch(getAllRooms({ accessToken }));
		dispatch(getAllCategories({ accessToken }));
	}, []);

	const showModal = (initialValues = null, index = null) => {
		if (initialValues) {
			form.setFieldsValue({
				...initialValues,
				categories: initialValues.categories.split(","),
			});
		} else {
			form.resetFields();
			form.setFieldsValue({
				categories: categories.map((c) => c.name),
			});
		}
		setEditingRoomIndex(index);
		setEditingRoom(initialValues);
		setOpen(true);
	};

	const handleOk = async () => {
		try {
			await form.validateFields();
			const formValues = form.getFieldsValue();
			form.resetFields();
			if (editingRoom?.id) {
				dispatch(
					updateRoom({
						body: { ...formValues, id: editingRoom.id },
						accessToken,
					})
				);
			} else {
				dispatch(createRoom({ body: formValues, accessToken }));
			}
			setOpen(false);
			resetLists();
		} catch (error) {
			console.log("Form validation error:", error);
		}
	};

	const handleDelete = (id) => {
		dispatch(deleteRoom({ id, accessToken }));
	};

	const handleEmailChange = (
		type,
		index,
		newValue,
		edited,
		setEdited,
		created,
		setCreated
	) => {
		const editedEmails = [...edited];
		const isNew = index > rooms[editingRoomIndex]?.[type].length - 1;
		if (!isNew) {
			editedEmails[index] = {
				old: rooms[editingRoomIndex]?.[type][index],
				new: newValue,
			};
			setEdited(editedEmails);
		} else {
			const createdEmails = [...created];
			createdEmails[index] = newValue;
			setCreated(createdEmails);
		}
	};

	const handleEmailDelete = (
		type,
		index,
		deleted,
		setDeleted,
		created,
		setCreated
	) => {
		const deletedEmail = rooms[editingRoomIndex]?.[type][index];
		if (deletedEmail) {
			const deletedEmails = [...deleted, deletedEmail];
			setDeleted(deletedEmails);
		} else {
			// Email was newly added and now the same being removed
			const updatedCreated = created.filter((v, i) => index !== i);
			setCreated(updatedCreated);
		}
	};

	const resetLists = () => {
		setEditedHostEmails([]);
		setDeletedHostEmails([]);
		setCreatedHostEmails([]);
		setEditedLeadEmails([]);
		setDeletedLeadEmails([]);
		setCreatedLeadEmails([]);
		setEditedContacts([]);
		setDeletedContacts([]);
		setCreatedContacts([]);
	};

	const handleCancel = () => {
		form.resetFields();
		setOpen(false);
	};

	return (
		<div className={RoomrSCSS.container}>
			<Button
				type="primary"
				onClick={() => showModal(null)}
				icon={<PlusOutlined />}
				style={{ marginBottom: "20px" }}
			>
				Create new Room
			</Button>

			<Modal
				open={open}
				title={
					editingRoom?.id
						? "Update Room " + editingRoom.link
						: "Create new room"
				}
				onCancel={handleCancel}
				footer={null}
			>
				<Form form={form} layout={"vertical"} onFinish={handleOk}>
					<Form.Item
						label="Room name"
						name="name"
						rules={[{ required: true, message: "Please enter room name" }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						rules={[
							{
								required: true,
								message: "Please select at least one category",
							},
						]}
						label="Categories"
						name="categories"
					>
						<Checkbox.Group
							style={{
								width: "100%",
							}}
						>
							<Row>
								{categories.map((c) => (
									<Col span={8} key={c.id}>
										<Checkbox value={c.name}>{c.name}</Checkbox>
									</Col>
								))}
							</Row>
						</Checkbox.Group>
					</Form.Item>

					<Form.Item label="Cheat sheet link" name="cheatSheetLink">
						<Input />
					</Form.Item>

					<Form.Item label="Schedule link" name="scheduleLink">
						<Input />
					</Form.Item>

					<Form.List name="leads">
						{(fields, { add, remove }) => (
							<div>
								{fields.map((field, index) => (
									<Form.Item
										label={index === 0 ? "Lead emails" : ""}
										required={false}
										key={field.key}
									>
										<Form.Item
											{...field}
											validateTrigger={["onChange", "onBlur"]}
											rules={[
												{
													type: "email",
													message: "Invalid email format",
												},
											]}
											style={{ marginBottom: 0 }}
										>
											<Input
												placeholder="lead email"
												onChange={(e) =>
													handleEmailChange(
														"leads",
														index,
														e.target.value,
														editedLeadEmails,
														setEditedLeadEmails,
														createdLeadEmails,
														setCreatedLeadEmails
													)
												}
											/>
										</Form.Item>
										{fields.length > 0 ? (
											<Button
												type="link"
												style={{ color: "grey" }}
												onClick={() => {
													handleEmailDelete(
														"leads",
														index,
														deletedLeadEmails,
														setDeletedLeadEmails,
														createdLeadEmails,
														setCreatedLeadEmails
													);
													remove(field.name);
												}}
											>
												Remove
											</Button>
										) : null}
									</Form.Item>
								))}
								<Form.Item>
									<Button
										type="dashed"
										onClick={() => {
											add();
										}}
										icon={<PlusOutlined />}
									>
										Add lead email
									</Button>
								</Form.Item>
							</div>
						)}
					</Form.List>

					<Form.List name="hosts">
						{(fields, { add, remove }) => (
							<div>
								{fields.map((field, index) => (
									<Form.Item
										label={index === 0 ? "Host access emails" : ""}
										required={false}
										key={field.key}
									>
										<Form.Item
											{...field}
											validateTrigger={["onChange", "onBlur"]}
											rules={[
												{
													type: "email",
													message: "Invalid email format",
												},
											]}
											style={{ marginBottom: 0 }}
										>
											<Input
												placeholder="Host email"
												onChange={(e) =>
													handleEmailChange(
														"hosts",
														index,
														e.target.value,
														editedHostEmails,
														setEditedHostEmails,
														createdHostEmails,
														setCreatedHostEmails
													)
												}
											/>
										</Form.Item>
										{fields.length > 0 ? (
											<Button
												type="link"
												style={{ color: "grey" }}
												onClick={() => {
													handleEmailDelete(
														"hosts",
														index,
														deletedHostEmails,
														setDeletedHostEmails,
														createdHostEmails,
														setCreatedHostEmails
													);
													remove(field.name);
												}}
											>
												Remove
											</Button>
										) : null}
									</Form.Item>
								))}
								<Form.Item>
									<Button
										type="dashed"
										onClick={() => {
											add();
										}}
										icon={<PlusOutlined />}
									>
										Add host email
									</Button>
								</Form.Item>
							</div>
						)}
					</Form.List>

					<Form.List name="contacts">
						{(fields, { add, remove }) => (
							<div>
								{fields.map((field, index) => (
									<Form.Item
										label={index === 0 ? "Contacts" : ""}
										required={false}
										key={field.key}
									>
										<Form.Item
											{...field}
											validateTrigger={["onChange", "onBlur"]}
											style={{ marginBottom: 0 }}
										>
											<Input
												placeholder="Contact"
												onChange={(e) =>
													handleEmailChange(
														"contacts",
														index,
														e.target.value,
														editedContacts,
														setEditedContacts,
														createdContacts,
														setCreatedContacts
													)
												}
											/>
										</Form.Item>
										{fields.length > 0 ? (
											<Button
												type="link"
												style={{ color: "grey" }}
												onClick={() => {
													handleEmailDelete(
														"contacts",
														index,
														deletedContacts,
														setDeletedContacts,
														createdContacts,
														setCreatedContacts
													);
													remove(field.name);
												}}
											>
												Remove
											</Button>
										) : null}
									</Form.Item>
								))}
								<Form.Item>
									<Button
										type="dashed"
										onClick={() => {
											add();
										}}
										icon={<PlusOutlined />}
									>
										Add Contact
									</Button>
								</Form.Item>
							</div>
						)}
					</Form.List>

					<Form.Item>
						<Space>
							<Button type="primary" onClick={handleOk}>
								{editingRoom?.id ? "Update" : "Create"}
							</Button>
							<Button onClick={handleCancel}>Close</Button>
						</Space>
					</Form.Item>
				</Form>
			</Modal>
			<Table
				dataSource={rooms}
				columns={columns}
				loading={loading}
				rowKey="id"
			/>
		</div>
	);
};

export default Room;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ORIGIN } from "../config";
import axios from "axios";
import { message } from "antd";

export const getAllUsers = createAsyncThunk(
	"user/getAll",
	async ({ accessToken }, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(`${API_ORIGIN}/user/all`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const updateUserRole = createAsyncThunk(
	"user/updateRole",
	async ({ accessToken, userId, newRole }, { rejectWithValue }) => {
		try {
			const { data } = await axios.put(
				`${API_ORIGIN}/user/${userId}/role`,
				{ newRole },
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

const userSlice = createSlice({
	name: "user",
	initialState: {
		users: [],
		error: null,
		loading: false,
	},
	reducers: {},
	extraReducers: {
		[getAllUsers.pending]: (state) => {
			state.loading = true;
		},
		[getAllUsers.fulfilled]: (state, { payload }) => {
			state.users = payload.users;
			state.loading = false;
		},
		[getAllUsers.rejected]: (state, { payload }) => {
			state.loading = false;
			message.error(`Error: ${payload.message}`);
		},
		[updateUserRole.pending]: (state) => {
			state.loading = true;
		},
		[updateUserRole.fulfilled]: (state, { payload }) => {
			const userIndex = state.users.findIndex(
				(user) => user.id === payload.user.id
			);
			state.users[userIndex] = { ...state.users[userIndex], ...payload.user };
			state.loading = false;
		},
		[updateUserRole.rejected]: (state, { payload }) => {
			state.loading = false;
			message.error(`Error: ${payload.message}`);
		},
	},
});

export default userSlice;

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { apiSlice } from "./apiSlice"
import { reducer as appReducer } from "./appSlice";
import roomSlice from "./roomSlice";
import userSlice from "./userSlice";
import listItemsSlice from "./listItems";

const rootReducer = combineReducers({
  form: formReducer,
  app: appReducer,
  room: roomSlice.reducer,
  listItems: listItemsSlice.reducer,
  user: userSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export default rootReducer;

import React from "react";
import { Typography } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingDots from "../loading-dots/LoadingDots";
import logo from "../../assets/logo.png";

const WaitingSession = () => {
	const { isAuthenticated, loginWithPopup } = useAuth0();
	const { Link, Text } = Typography;

	return (
		<div >
			<img  src={logo}></img>
			{isAuthenticated ? (
				<Text>Please wait to be let into the session.</Text>
			) : (
				<>
					<Text>Please wait to be let into the session.</Text>
					<Text>
						{" "}
						If you are a host/volunteer,{" "}
						<Link onClick={() => loginWithPopup()}>click here.</Link>
					</Text>
				</>
			)}
			<div style={{ marginTop: "10px" }}>
				<LoadingDots />
			</div>
		</div>
	);
};

export default WaitingSession;

import React, { useState } from "react";
import { useDispatch } from "../../store";
import { setAppContext } from "../../store/appSlice";
import { Button, Form, Input, message, Image } from "antd";
import ProfileSCSS from "./Profile.module.scss";
import { API_ORIGIN } from "../../config";
import axios from "axios";
import { useSelector } from "react-redux";

const Profile = () => {
	const dispatch = useDispatch();
	const [messageApi, contextHolder] = message.useMessage();
	const appContext = useSelector((state) => state.app);
	const [loading, setLoading] = useState(false);

	const { user, accessToken } = appContext.context;

	const onFinish = async (values) => {
		setLoading(true);
		try {
			const updatedUserMetadata = {
				givenName: values.name,
				familyName: values.surname,
			};

			const updatedUser = await axios.put(
				`${API_ORIGIN}/user/me`,
				updatedUserMetadata,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);

			dispatch(
				setAppContext({
					context: {
						...appContext.context,
						user: { ...user, ...updatedUser.data.user },
					},
				})
			);

			messageApi.open({
				type: "success",
				content: "User profile updated!",
			});
		} catch (error) {
			messageApi.open({
				type: "error",
				content: "Error updating user profile!",
			});
		}
		setLoading(false);
	};

	const onFinishFailed = (errorInfo) => {
		messageApi.open({
			type: "error",
			content: "Invalid form data!",
		});
	};

	return (
		user && (
			<div className={ProfileSCSS.container}>
				{contextHolder}
				<div className={ProfileSCSS.inputs}>
					<Form
						name="basic"
						labelCol={{
							span: 6,
						}}
						wrapperCol={{
							span: 24,
						}}
						initialValues={{
							name: user.givenName,
							surname: user.familyName,
							email: user.email,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<div className={ProfileSCSS.imageContainer}>
							<Image width={90} src={user.picture} />
						</div>
						<Form.Item
							name="name"
							rules={[
								{
									required: true,
									message: "Please input your first name!",
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							name="surname"
							rules={[
								{
									required: true,
									message: "Please input your last name!",
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							name="email"
							rules={[
								{
									required: true,
								},
							]}
						>
							<Input disabled={true} />
						</Form.Item>
						<Form.Item
							wrapperCol={{
								offset: 9,
							}}
						>
							<Button loading={loading} type="primary" htmlType="submit">
								Update
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		)
	);
};

export default Profile;

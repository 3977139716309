import React from "react";
import LoadingSCSS from "./Loading.module.scss";
import LoadingDots from "../loading-dots/LoadingDots";

const Loading = () => {
	return (
		<div className={LoadingSCSS.container}>
			<LoadingDots />
		</div>
	);
};

export default Loading;

import React, { useEffect, useState } from "react";
import Storage from "store2";
import { useAuth0 } from "@auth0/auth0-react";
import { camelizeKeys } from "humps";
import { API_ORIGIN, AUTH0_DOMAIN } from "./config";
import { useDispatch } from "./store";
import { setAppContext } from "./store/appSlice";

const AppContext = React.createContext({
	accessToken: null,
	user: {},
	storage: null,
});

export class AppContextStorage {
	constructor(sub) {
		this.sub = sub;
		this.storage = Storage.namespace(this.sub);
	}
}

export const useAppContext = (options = {}) => {
	const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
	const dispatch = useDispatch();

	const [refreshIndex, setRefreshIndex] = useState(0);
	useEffect(() => {
		(async () => {
			try {
				if (!isAuthenticated) return;
				const { audience, scope, ...fetchOptions } = options;
				const accessToken = await getAccessTokenSilently({ audience, scope });

				const checkRegisterUser = await fetch(`${API_ORIGIN}/user/me`, {
					...fetchOptions,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});

				const userDetailsByIdUrl = `https://${AUTH0_DOMAIN}/api/v2/users/${user.sub}`;
				const metadataResponse = await fetch(userDetailsByIdUrl, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});

				const context = {
					user: {
						...camelizeKeys(await metadataResponse.json()),
						...camelizeKeys(await checkRegisterUser.json()).user
					},
					accessToken,
				};
				const resultState = {
					context,
					error: null,
					loading: false,
				};

				dispatch(setAppContext(resultState));
			} catch (error) {
				dispatch(
					setAppContext({
						error,
						loading: false,
					})
				);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshIndex, isAuthenticated]);

	return {
		refresh: () => setRefreshIndex(refreshIndex + 1),
	};
};

export const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;

export default AppContext;

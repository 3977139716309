import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ORIGIN } from "../config";
import axios from "axios";
import { message } from "antd";
import { createClient } from "agora-rtc-react";
import { AGORA_APP_ID } from "../config";

export const createRoom = createAsyncThunk(
	"room/create",
	async ({ body, accessToken }, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(`${API_ORIGIN}/room`, body, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const updateRoom = createAsyncThunk(
	"room/update",
	async ({ body, accessToken }, { rejectWithValue }) => {
		try {
			const { data } = await axios.put(`${API_ORIGIN}/room/${body.id}`, body, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const deleteRoom = createAsyncThunk(
	"room/delete",
	async ({ id, accessToken }, { rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`${API_ORIGIN}/room/${id}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			return { ...data, id };
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const getAllRooms = createAsyncThunk(
	"room/getAll",
	async ({ accessToken }, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(`${API_ORIGIN}/room`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const getRoomData = createAsyncThunk(
	"room/room-data",
	async ({ code, accessToken }, { rejectWithValue }) => {
		try {
			if (!code) {
				return rejectWithValue();
			}
			const headers = accessToken
				? { Authorization: `Bearer ${accessToken}` }
				: null;
			const { data } = await axios.get(`${API_ORIGIN}/room/${code}/room-data`, {
				headers,
			});
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

const roomSlice = createSlice({
	name: "room",
	initialState: {
		rooms: [],
		error: null,
		agoraClient: null,
		joinedRoom: null,
		loading: true,
	},
	reducers: {},
	extraReducers: {
		[createRoom.pending]: (state) => {
			state.loading = true;
		},
		[createRoom.fulfilled]: (state, { payload }) => {
			const sortedRooms = [payload.room, ...state.rooms]
				.slice()
				.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
			state.rooms = sortedRooms;
			state.loading = false;
		},
		[createRoom.rejected]: (state, { payload }) => {
			state.loading = false;
			message.error(`Error creating room: ${payload.message}`);
		},
		[getAllRooms.pending]: (state) => {
			state.loading = true;
		},
		[getAllRooms.fulfilled]: (state, { payload }) => {
			const sortedRooms = payload.rooms.slice().sort((a, b) => {
				return a.name.localeCompare(b.name);
			});
			state.rooms = sortedRooms;
			state.loading = false;
		},
		[getAllRooms.rejected]: (state, { payload }) => {
			state.loading = false;
			message.error(`Error: ${payload.message}`);
		},
		[updateRoom.pending]: (state) => {
			state.loading = true;
		},
		[updateRoom.fulfilled]: (state, { payload }) => {
			const roomIndex = state.rooms.findIndex(
				(room) => room.id === payload.room.id
			);
			state.rooms[roomIndex] = { ...state.rooms[roomIndex], ...payload.room };
			state.loading = false;
		},
		[updateRoom.rejected]: (state, { payload }) => {
			state.loading = false;
			message.error(`Error updating room: ${payload.message}`);
		},
		[deleteRoom.pending]: (state, { payload }) => {
			state.loading = true;
		},
		[deleteRoom.fulfilled]: (state, { payload }) => {
			state.rooms = state.rooms.filter((r) => r.id !== payload.id);
			state.loading = false;
		},
		[deleteRoom.rejected]: (state, { payload }) => {
			state.loading = false;
			message.error(`Error deleting room: ${payload.message}`);
		},
		[getRoomData.pending]: (state) => {
			state.error = null;
			state.loading = true;
		},
		[getRoomData.fulfilled]: (state, { payload }) => {
			const config = {
				mode: "rtc",
				codec: "vp8",
				AGORA_APP_ID,
				token: payload.data.token,
			};
			state.agoraClient = createClient(config);
			state.joinedRoom = payload.data;
			state.loading = false;
		},
		[getRoomData.rejected]: (state, { payload }) => {
			if (payload) {
				state.error = payload;
				message.error(`${payload.message}`);
			}
			state.loading = false;
		},
	},
});

export default roomSlice;

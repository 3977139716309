import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { configureStore } from './store';
import AppContext, { useAppContext } from './context';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from './config';
import App from './App';
import Loading from './components/loading/Loading';
import reportWebVitals from './reportWebVitals';
import { useSelector } from "react-redux";
import { ConfigProvider } from 'antd';
import './index.css';

const AppWithProtection = (() => {
	const appContext = useSelector((state) => state.app);
  const { isLoading } = useAuth0();

	const { context } = appContext;
  useAppContext(
    {
      audience: `https://${AUTH0_DOMAIN}/api/v2/`,
      scope: 'read:current_user',
    },
  );
  if (isLoading) {
    return <Loading />;
  }

  return (
    <AppContext.Provider value={context}>
      <ConfigProvider theme={{
        token: {
          colorPrimary: '#00a3d7',
        },
      }}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </AppContext.Provider>
  );
});

createRoot(document.getElementById('root')).render(
  <Provider store={configureStore()}>
    <Suspense fallback={<Loading />}>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        authorizationParams={{
          redirectUri: window.location.origin,
          audience: `https://${AUTH0_DOMAIN}/api/v2/`,
          scope: "read:current_user update:current_user_metadata"
        }}
      >
        <AppWithProtection />
      </Auth0Provider>
    </Suspense>
  </Provider>
);

reportWebVitals();

import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import VideoCall from "../../components/video-call/VideoCall";
import { useDispatch } from "../../store";
import { getRoomData } from "../../store/roomSlice";
import Loading from "../../components/loading/Loading";
import { useParams, useNavigate } from "react-router-dom";
import JoinRoom from "../../components/join-room/JoinRoom";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, message, Typography, Row, Col } from "antd";
import { v4 as uuidv4 } from "uuid";
import { API_ORIGIN } from "../../config";
import io from "socket.io-client";
import WaitingSession from "../../components/waiting-session/WaitingSession";
import LoadingDots from "../../components/loading-dots/LoadingDots";
import WaitingSessionSCSS from "./WaitingSession.module.scss";
import logo from "../../assets/GT---animated-logo-BLK-1.gif";

const WaitingRoom = () => {
	const { isAuthenticated, isLoading, loginWithPopup } = useAuth0();
	const appContext = useSelector((state) => state.app);
	const { accessToken, user } = appContext.context;
	const [waiting, setWaiting] = useState(true);
	const [hasRequested, setHasRequested] = useState(false);
	const [userToJoin, setuserToJoin] = useState({});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const socket = io(API_ORIGIN, { transports: ["websocket"] });
	const waitingRef = useRef(true);
	const isHostRef = useRef(false);
	const hasRequestedRef = useRef(false);

	const [messageApi, contextHolder] = message.useMessage();

	const { loading, joinedRoom, agoraClient, error } = useSelector(
		(state) => state.room
	);
	const { code } = useParams();

	const { Link, Text } = Typography;

	const isPopupOpen = useRef(false);

	const handleLoginWithPopup = () => {
		isPopupOpen.current = true;
		loginWithPopup()
			.then(() => {
				isPopupOpen.current = false;
			})
			.catch(() => {
				isPopupOpen.current = false;
			});
	};

	useEffect(() => {
		waitingRef.current = waiting;
	}, [waiting]);

	useEffect(() => {
		hasRequestedRef.current = hasRequested;
	}, [hasRequested]);

	useEffect(() => {
		if (isAuthenticated && !accessToken) {
			return;
		}
		setuserToJoin({
			uid: user ? user.id : uuidv4(),
			fullName: user ? user.givenName : "Attendee",
		});
		dispatch(getRoomData({ code, accessToken }));
	}, [code, user]);

	useEffect(() => {
		if (isAuthenticated && !user) {
			return;
		}
		if (joinedRoom) {
			isHostRef.current = joinedRoom.isUserHost;
			if (joinedRoom.isUserHost) {
				waitingRef.current = false;
				setWaiting(false);
				return;
			}
			socket.emit("join_room", joinedRoom.room.link);
			if (isAuthenticated && !hasRequested && !joinedRoom.isUserHost) {
				const u = {
					uid: user ? user.id : uuidv4(),
					fullName: user ? user.givenName : "Attendee",
				};
				socket.emit("request_to_join", {
					user: u,
					room: joinedRoom.room.link,
				});
				setHasRequested(true);
			}
		}
	}, [joinedRoom]);

	const requestToJoin = () => {
		socket.emit("request_to_join", {
			user: userToJoin,
			room: joinedRoom.room.link,
		});
		setHasRequested(true);
	};

	useEffect(() => {
		socket.on("status_updated", ({ status, uid }) => {
			if (joinedRoom.isUserHost || uid !== userToJoin.uid) return;
			if (status === "admit") {
				waitingRef.current = false;
				setWaiting(false);
			} else if (status === "room_full") {
				message.warning("You have been denied entry. Room is full.");
				socket.off();
				navigate("/");
			} else {
				message.warning("You were denied by room host.");
				socket.off();
				navigate("/");
			}
		});

		socket.on("notify_host_joined", () => {
			if (isHostRef.current || !waitingRef.current || !hasRequestedRef.current) {
				return;
			}
			if (userToJoin.uid) {
				socket.emit("request_to_join", {
					user: userToJoin,
					room: joinedRoom.room.link,
				});
			}
		});
	}, [socket]);

	if (loading || isLoading) {
		return <Loading />;
	}

	if (waiting && code && joinedRoom && !joinedRoom?.isUserHost) {
		return (
			<div className={WaitingSessionSCSS.container}>
				<img className={WaitingSessionSCSS.logo} src={logo}></img>
				{!hasRequested ? (
					<>
						{!isAuthenticated && (
							<div className={WaitingSessionSCSS.innerContainer}>
								<div className={WaitingSessionSCSS.block}>
									<Text>
										If you are an <b>attendee</b> (student, etc), <br />
										please{" "}
										<Link onClick={() => requestToJoin()}>click here</Link> to
										access the waiting room
									</Text>
								</div>
								<div className={WaitingSessionSCSS.divider}></div>
								<div>
									<div className={WaitingSessionSCSS.block}>
										<Text>
											If you are <b>host</b> (volunteer, tutor, etc), <br />
											please{" "}
											<Link onClick={handleLoginWithPopup}>click here</Link> to
											log in or sign up
										</Text>{" "}
									</div>
								</div>
							</div>
						)}
					</>
				) : (
					<>
						<Text>Please wait to be let into the session.</Text>
						<LoadingDots style={{ marginTop: "10px" }} />
					</>
				)}
				<Row className={WaitingSessionSCSS.links} justify="center">
					<Col span={12}>
						<Link to="/terms" target="_blank">
							Terms
						</Link>{" "}
						-{" "}
						<Link to="/privacy" target="_blank">
							Privacy
						</Link>{" "}
						-{" "}
						<Link to="https://www.goodte.am/trust-and-safety" target="_blank">
							Learn more
						</Link>
					</Col>
				</Row>
			</div>
		);
	}

	if (error || !code || !agoraClient) {
		return (
			<>
				{contextHolder}
				<JoinRoom />
			</>
		);
	}

	return (
		<div style={{ display: "flex", justifyContent: "center" }}>
			<div style={{ width: "100%", maxWidth: "1400px" }}>
				<VideoCall
					socket={socket}
					userToJoin={userToJoin}
					joinedRoom={joinedRoom}
					agoraClient={agoraClient}
				/>
			</div>
		</div>
	);
};

export default WaitingRoom;

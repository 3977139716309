import { createSlice } from "@reduxjs/toolkit";
import { AppContextStorage } from "../context";

const initialState = {
  context: { user: null, accessToken: null},
  isLoading: true,
  storage: null,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setContext(state, action) {
      state.isLoading = false;
      if (!action.payload.context) return;
      state.context = action.payload.context;
      const userData = action.payload.context.user;
      state.context.user = {
        ...state.context.user,
        ...userData
      };
    },
    setStorage(state, action) {
      state.storage = new AppContextStorage(action.payload)
    },
  },
});

export const reducer = slice.reducer;

/*
 * Actions
 */

export const setAppContext = (context) => async (dispatch) => {
  dispatch(slice.actions.setContext(context))
};

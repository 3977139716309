import React from "react";
import TermsSCSS from "./Terms.module.scss";
import Header from "../../components/header/Header";
import { Typography } from "antd";

const { Title, Text } = Typography;

const Terms = () => {
	return (
		<>
			<Header />
			<div className={TermsSCSS.container}>
				<div className={TermsSCSS.subContainer}>
					<Title level={1}>Terms of Service</Title>
					<Title level={3}>1. Acceptance of Terms</Title>
					<Text>
						By using our platform (the "Platform"), you agree to be bound by
						these terms of use (the "Terms"). If you do not agree to these
						Terms, you may not use the Platform.
					</Text>
					<Title level={3}>2. Eligibility</Title>
					<Text>
						To use the Platform, you must be at least 18 years old and have the
						legal capacity to enter into a binding contract. If you are under
						the age of 18, your parent or guardian must agree to these Terms on
						your behalf.
					</Text>
					<Title level={3}>3. Account Creation</Title>
					<Text>
						To create an account on the Platform, you must provide us with
						certain personal information, such as your name, email address, and
						username. You agree that all information you provide to us is
						accurate and complete.
					</Text>
					<Title level={3}>4. Use of the Platform</Title>
					<Text>
						You may use the Platform to video chat with other users and to
						access educational tools. You agree to use the Platform in a
						responsible and ethical manner. You agree not to use the Platform
						for any unlawful or unauthorized purpose, including but not limited
						to:
					</Text>
					<ul>
						<li>
							<Text>
								Posting or transmitting any content that is harmful,
								threatening, abusive, harassing, defamatory, vulgar, obscene,
								pornographic, or otherwise objectionable.
							</Text>
						</li>
						<li>
							<Text>
								Impersonating any person or entity, or falsely stating or
								otherwise misrepresenting your affiliation with any person or
								entity.
							</Text>
						</li>
						<li>
							<Text>
								Transmitting or uploading any viruses, Trojan horses, or other
								malicious software.
							</Text>
						</li>
						<li>
							<Text>
								Interfering with or disrupting the Platform or the servers or
								networks connected to the Platform.
							</Text>
						</li>
						<li>
							<Text>Violating any applicable laws or regulations.</Text>
						</li>
					</ul>
					<Title level={3}>5. Parental Controls</Title>
					<Text>
						Parents or guardians are always welcome to directly monitor their
						child's use of the Platform. Parents or guardians are responsible
						for understanding our security measures, privacy policy, their
						child's use of the Platform (ensuring that their child complies with
						these Terms), and knowing when the children under their care are
						scheduled to use the platform, whether they are organizing these
						sessions themselves or they are scheduled through a school or
						community center.
					</Text>
					<Title level={3}>6. Intellectual Property</Title>
					<Text>
						The Platform and all content on the Platform, including but not
						limited to text, images, videos, and software, are protected by
						copyright and other intellectual property laws. You agree not to
						copy, modify, distribute, transmit, or otherwise exploit the
						Platform or any content on the Platform without our express written
						permission.
					</Text>
					<Title level={3}>7. Disclaimer of Warranties</Title>
					<Text>
						The Platform is provided on an "as is" and "as available" basis. We
						make no warranties, express or implied, with respect to the
						Platform, including but not limited to any warranties of
						merchantability, fitness for a particular purpose, or
						non-infringement.
					</Text>
					<Title level={3}>8. Limitation of Liability</Title>
					<Text>
						To the fullest extent permitted by law, we shall not be liable for
						any direct, indirect, incidental, special, consequential, or
						exemplary damages arising out of or in connection with your use of
						the Platform, even if we have been advised of the possibility of
						such damages.
					</Text>
					<Title level={3}>9. Termination</Title>
					<Text>
						We may terminate your account and access to the Platform at any
						time, for any reason, without notice.
					</Text>
					<Title level={3}>10. Governing Law</Title>
					<Text>
						These Terms shall be governed by and construed in accordance with
						the laws of the State of California, without regard to its
						principles of conflict of laws.
					</Text>
					<Title level={3}>11. Arbitration</Title>
					<Text>
						This agreement to arbitrate is between Good Team, all of Good Team's
						users, and all of Good Team's vendors and third party service
						providers, including the providers of any underlying technology.<br/><br/>
						Any dispute or claim arising out of or relating to this Agreement,
						including any breach thereof, shall be finally settled by binding
						arbitration in accordance with the American Arbitration Association
						(AAA) then in effect. The arbitration shall be held in Alameda
						Country, California before a single arbitrator mutually agreed upon
						by the parties. If the parties cannot agree on an arbitrator within
						60, the arbitrator shall be appointed by The American Arbitration
						Association (AAA). The arbitrator's award shall be final and binding
						on the parties and may be entered as a judgment in any court of
						competent jurisdiction.<br/><br/>The parties agree that any arbitration
						hearing may be conducted remotely using secure videoconferencing.
						The parties shall cooperate in good faith to ensure that the
						arbitration hearing is conducted in a fair and efficient manner.<br/><br/>
						This arbitration clause shall survive the termination or expiration
						of this Agreement.
					</Text>
					<Title level={3}>12. Entire Agreement</Title>
					<Text>
						These Terms, along with any direct waiver that applies to your
						specific program, constitute the entire agreement between you and us
						with respect to your use of the Platform.
					</Text>
					<Title level={3}>13. Severability</Title>
					<Text>
						If any provision of these Terms is held to be invalid or
						unenforceable, such provision shall be struck from these Terms and
						the remaining provisions shall remain in full force and effect.
					</Text>
					<Title level={3}>14. Changes to the Terms</Title>
					<Text>
						We may update these Terms from time to time. If we make any material
						changes to these Terms, we will notify you by email or by posting a
						notice on the Platform.
					</Text>
					<Title level={3}>15. Contact Us</Title>
					<Text>
						If you have any questions about these Terms or the Platform, please
						contact us at <a href="mailto:dpo@goodte.am">dpo@goodte.am</a>.
					</Text>
				</div>
			</div>
		</>
	);
};

export default Terms;

import React, { useEffect, useState } from "react";
import UsersSCSS from "./Users.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Table, Select, Button, Input } from "antd";
import { getAllUsers, updateUserRole } from "../../../store/userSlice";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { UserRole } from "../../../constants/userRole";

const { Option } = Select;

const Users = () => {
	const dispatch = useDispatch();
	const appContext = useSelector((state) => state.app);
	const { user, accessToken } = appContext.context;
	const { users, loading } = useSelector((state) => state.user);

	const [changedRoles, setChangedRoles] = useState({});
	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		dispatch(getAllUsers({ accessToken }));
	}, [dispatch]);

	const filteredUsers = users.filter(
		(user) =>
			user.givenName.toLowerCase().includes(searchText.toLowerCase()) ||
			user.familyName.toLowerCase().includes(searchText.toLowerCase()) ||
			user.email.toLowerCase().includes(searchText.toLowerCase())
	);

	const handleSearch = (ev) => {
		setSearchText(ev.target.value);
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "givenName",
			key: "givenName",
		},
		{
			title: "Surname",
			dataIndex: "familyName",
			key: "familyName",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Role",
			dataIndex: "role",
			key: "role",
			render: (text, record) => (
				<div className={UsersSCSS.confirmButtonContainer}>
					<Select
						disabled={
							record.role === UserRole.SUPER_ADMIN &&
							user.role !== UserRole.OWNER
						}
						style={{ width: "130px" }}
						value={changedRoles[record.id] || text}
						onChange={(value) => handleRoleChange(record, value)}
					>
						<Option value="user">User</Option>
						<Option value="admin">Admin</Option>
						<Option disabled={user.role !== UserRole.OWNER} value="super-admin">
							Super Admin
						</Option>
					</Select>
					{changedRoles[record.id] && (
						<div>
							<Button
								onClick={() => handleConfirm(record)}
								type="primary"
								shape="circle"
								icon={<CheckOutlined />}
							/>
							<Button
								onClick={() => handleDiscard(record)}
								type="primary"
								shape="circle"
								icon={<CloseOutlined />}
							/>
						</div>
					)}
				</div>
			),
		},
	];

	const handleRoleChange = (user, value) => {
		setChangedRoles((prevRoles) => ({
			...prevRoles,
			[user.id]: value,
		}));
	};

	const handleConfirm = (user) => {
		dispatch(
			updateUserRole({
				accessToken,
				userId: user.id,
				newRole: changedRoles[user.id],
			})
		);
		setChangedRoles((prevRoles) => {
			const newRoles = { ...prevRoles };
			delete newRoles[user.id];
			return newRoles;
		});
	};

	const handleDiscard = (user) => {
		setChangedRoles((prevRoles) => {
			const newRoles = { ...prevRoles };
			delete newRoles[user.id];
			return newRoles;
		});
	};

	return (
		<div>
			<Input
				placeholder="Search by name, surname, or email"
				allowClear
				size="large"
				onChange={(e) => handleSearch(e)}
				style={{ marginBottom: 16 }}
			/>
			<Table
				columns={columns}
				dataSource={filteredUsers}
				loading={loading}
				rowKey="id"
			/>
		</div>
	);
};

export default Users;

import React, { useState, useEffect } from "react";
import JoinRoomSCSS from "./JoinRoom.module.scss";
import Header from "../header/Header";
import { Button, Row, Col, Form, Input, Typography } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useNavigate } from "react-router-dom";

const JoinRoom = () => {
	const { loginWithRedirect, isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const { Title, Text } = Typography;

	const onFinish = (values) => {
		navigate(`/${values.code}`);
	};

	const capitalizeInput = (e) => {
		e.target.value = e.target.value.toUpperCase();
	};

	return (
		<>
			<Header />
			<Row justify="center">
				<Col className={JoinRoomSCSS.card} span={12}>
					<Form
						layout="vertical"
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
					>
						<Form.Item
							name="code"
							rules={[
								{
									required: true,
									message: "Input room code!",
								},
								{
									max: 6,
								},
							]}
						>
							<Input
								placeholder="Room code"
								size="large"
								onInput={capitalizeInput}
								maxLength={6}
							/>
						</Form.Item>

						<Form.Item>
							<Button
								size="large"
								type="primary"
								htmlType="submit"
								style={{ width: "100%" }}
							>
								Join
							</Button>
						</Form.Item>
					</Form>
					{!isAuthenticated ? (
						<Text>
							Login or register <a onClick={loginWithRedirect}>here</a>
						</Text>
					) : null}
				</Col>
			</Row>
			<Row className={JoinRoomSCSS.links} justify="center">
				<Col span={12}>
					<Link to="/terms" target="_blank">
						Terms
					</Link>{" "}
					-{" "}
					<Link to="/privacy" target="_blank">
						Privacy
					</Link>
					{" "}-{" "}
					<Link to="https://www.goodte.am/trust-and-safety" target="_blank">
						Learn more
					</Link>
				</Col>
			</Row>
		</>
	);
};

export default JoinRoom;

import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { camelizeKeys, decamelizeKeys } from "humps";
import axios from "axios";
import { API_ORIGIN } from "../config";

export const client = axios.create({
  baseURL: API_ORIGIN,
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use((config) => {
  if (config.params) {
    config.params = decamelizeKeys(config.params);
  }
  if (config.data) {
    config.data = decamelizeKeys(config.data);
  }
  return config;
});

client.interceptors.response.use((response) => {
  if (
    response.data &&
    response.headers["content-type"] === "application/json"
  ) {
    response.data = camelizeKeys(response.data);
  }
  return response;
});

// adds access tokens in all api requests
// this interceptor is only added when the auth0 instance is ready and exports the getAccessTokenSilently method
export const addAccessTokenInterceptor = (getAccessTokenSilently) => {
  client.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  });
};

export const useApi = (url, options = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: null,
  });
  const [refreshIndex, setRefreshIndex] = useState(0);
  useEffect(() => {
    (async () => {
      try {
        const { audience, scope, ...fetchOptions } = options;
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const res = await fetch(url, {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            // Add the Authorization header to the existing headers
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const resultState = {
          ...state,
          data: camelizeKeys(await res.json()),
          error: null,
          loading: false,
        };
        setState(resultState);
      } catch (error) {
        setState({
          ...state,
          error,
          loading: false,
        });
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshIndex]);

  return {
    ...state,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  };
};

export const fetchAll = async (url, page = 1) => {
  try {
    // Fetch request and parse as JSON
    const { data } = await client.get(url, { params: { page }})
    let { results, next } = data

    // If another page exists, merge its output into the array recursively
    if (next) {
      results = results.concat(await fetchAll(url, next));
    }
    return { data: results };
  } catch (err) {
    return console.error(err);
  }
}

const httpClient = {
  client,
  addAccessTokenInterceptor,
};

export default httpClient

export const API_ORIGIN = process.env.REACT_APP_API_ORIGIN || "http://localhost:5050";
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || "goodteam-dev.us.auth0.com";
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || "moUGCBdBy6l8H8Sdn2QFOv5U8Q3Jvvl5";
export const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID || "4a8d0101a2a740848f03c5e689824cfe";
export const SNAP_API_TOKEN =
	"eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNjk2MTg5NDc4LCJzdWIiOiI2YzljNjA0MC1kZTNkLTQyMTQtOWZiOC04MDhiZjBmNTlmOGR-U1RBR0lOR340ZGFiMTBhYi01MzM4LTQ1YWItYTM4NS1jNTZkN2VhNTcwMjQifQ.VlbKq0bmZmAV8jlBQz2_vkLaAiUbug0YqhnmteHBtqU"; // Replace with your API token
export const SNAP_LENS_GROUP_IDS = [
	"d335ed14-2e78-4d16-9898-309abf092f06",
	"34d37fa0-114e-4340-ac2d-a64a8a29ebc9",
	"1b4580ac-7303-4e36-9284-537cd9daf384",
	"b7515549-9e79-48f0-bda8-3299b2bdfaeb",
	"3699891c-0f77-4d0c-9a1f-2d47821c50f9",
];

import React from "react";
import LoadingDotsSCSS from "./LoadingDots.module.scss";

const LoadingDots = () => {
	return (
		<div className={LoadingDotsSCSS.dotsContainer}>
			<div className={LoadingDotsSCSS.dot}></div>
			<div className={LoadingDotsSCSS.dot}></div>
			<div className={LoadingDotsSCSS.dot}></div>
		</div>
	);
};

export default LoadingDots;

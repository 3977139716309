import React from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "./pages/landing/Landing";
import WaitingRoom from "./pages/waiting-room/WaitingRoom";
import Privacy from "./pages/privacy/Privacy";
import Terms from "./pages/terms/Terms";

const App = () => {
	return (
		<Routes>
			<Route path="/" element={<WaitingRoom />} />
			<Route path="user" element={<Landing />} />
			<Route path="privacy" element={<Privacy />} />
			<Route path="terms" element={<Terms />} />
			<Route path="/:code" element={<WaitingRoom />} />
		</Routes>
	);
};

export default App;

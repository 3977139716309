import { AgoraVideoPlayer } from "agora-rtc-react";
import { Row, Col } from "antd";
import { useState, useEffect, useRef } from "react";

export default function Video(props) {
	const { users, tracks, image, filter, uniqueUsers } = props;
	const [gridSpacing, setGridSpacing] = useState(12);
	const [height, setHeight] = useState("100%");
	const imageRef = useRef(null);

	useEffect(() => {
		imageRef.current = image;
	}, [image]);

	useEffect(() => {
		setGridSpacing(users.length < 2 ? 24 : 12);
		setHeight(users.length === 0 ? "60%" : "100%");
	}, [users, tracks]);

	return (
		<Row
			justify={"center"}
			style={{
				height: "100%",
				display: "flex",
				gap: users.length === 1 ? "10px" : "",
				width: users.length === 1 ? "50%" : "100%",
				alignItems: users.length === 1 ? "center" : "normal",
			}}
			gutter={12}
		>
			<Col
				style={{ height: users.length === 0 ? "100%" : "50%" }}
				span={users.length < 2 ? 24 : 12}
			>
				<div
					style={{
						display: image ? "flex" : "none",
						height: "100%",
						justifyContent: "center",
					}}
				>
					<img src={image}></img>
				</div>
				<div
					style={{
						height: users.length === 0 ? "100%" : height,
						width: "100%",
						display: filter || !image ? "block" : "none",
					}}
				>
					<div
						style={{
							height: users.length === 0 ? "100%" : height,
							width: "100%",
							display: !image ? "block" : "none",
						}}
					>
						<canvas
							style={{
								height: users.length === 0 ? "100%" : height,
								width: "100%",
								transform: "rotateY(180deg)",
								objectFit: "cover",
							}}
							id="canvas-container"
						></canvas>
					</div>
				</div>
				<div
					style={{
						position: "absolute",
						bottom: 0,
						background: "rgba(0,0,0,0.5)",
						color: "#fff",
						padding: "4px 8px",
					}}
				>
					You
				</div>
			</Col>
			{users.length > 0 &&
				users
					.filter((user) => user.videoTrack)
					.slice(0, 3)
					.map((user, index) => {
						const userObj = JSON.parse(user.uid);
						const u = uniqueUsers.find((u) => u.user.uid === userObj.uid);
						const imageId = `user-image-${u.user.uid}`;
						const agoraId = `user-agora-${u.user.uid}`;
						if (user.videoTrack) {
							return (
								<Col
									style={{ height: "50%" }}
									span={gridSpacing}
									key={user.uid}
								>
									<div
										id={imageId}
										style={{
											display: "none",
											height: "100%",
											justifyContent: "center",
											marginTop: "12px",
										}}
									>
										<img
											style={{
												height: "100%",
												width: "100%",
												objectFit: "cover",
											}}
										></img>
									</div>
									<AgoraVideoPlayer
										id={agoraId}
										videoTrack={user.videoTrack}
										style={{
											height,
											width: "100%",
											display: "block",
											marginTop: index > 0 ? "12px" : "0",
										}}
									/>
									<div
										style={{
											position: "absolute",
											bottom: index > 0 ? "-12px" : "0",
											background: "rgba(0,0,0,0.5)",
											color: "#fff",
											padding: "4px 8px",
										}}
									>
										{userObj.fullName}
									</div>
								</Col>
							);
						} else return null;
					})}
		</Row>
	);
}

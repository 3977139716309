import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ORIGIN } from "../config";
import axios from "axios";
import { message } from "antd";

export const getAllCategories = createAsyncThunk(
	"categories/getAll",
	async ({ accessToken }, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(`${API_ORIGIN}/list-items/categories`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const getSubcategories = createAsyncThunk(
	"subcategories/getAll",
	async ({ id, accessToken }, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				`${API_ORIGIN}/list-items/category/${id}/subcategories`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const getLists = createAsyncThunk(
	"lists/getAll",
	async ({ id, accessToken }, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				`${API_ORIGIN}/list-items/subcategory/${id}/lists`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const getListItems = createAsyncThunk(
	"list-items/getAll",
	async (
		{ id, accessToken, categoryId, subcategoryId },
		{ rejectWithValue }
	) => {
		try {
			const { data } = await axios.get(
				`${API_ORIGIN}/list-items/list/${categoryId}/${subcategoryId}/${id}/items`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

const listItemsSlice = createSlice({
	name: "listItems",
	initialState: {
		categories: [],
		subcategories: [],
		lists: [],
		error: null,
		joinedRoom: null,
		loading: true,
	},
	reducers: {},
	extraReducers: {
		[getAllCategories.pending]: (state) => {
			state.loading = true;
		},
		[getAllCategories.fulfilled]: (state, { payload }) => {
			state.categories = payload.categories;
			state.loading = false;
		},
		[getAllCategories.rejected]: (state, { payload }) => {
			state.loading = false;
			message.error(`Error getting categories`);
		},
		[getSubcategories.fulfilled]: (state, { payload }) => {
			state.subcategories = payload.subcategories;
		},
		[getLists.fulfilled]: (state, { payload }) => {
			state.lists = payload.lists;
		},
	},
});

export default listItemsSlice;

import React from "react";
import PrivacySCSS from "./Privacy.module.scss";
import Header from "../../components/header/Header";
import { Typography } from "antd";

const { Title, Text } = Typography;

const Privacy = () => {
	return (
		<>
			<Header />
			<div className={PrivacySCSS.container}>
				<div className={PrivacySCSS.subContainer}>
					<Title level={1}>Privacy Policy</Title>
					<Title level={3}>Introduction</Title>
					<Text>
						This privacy policy explains how we collect, use, and disclose
						personal information from users of our platform with educational
						tools. We are committed to protecting the privacy of all users, and
						we take special precautions to protect the privacy of children.
					</Text>
					<Title level={3}>Information We Collect</Title>
					<Text>
						We collect the following information from users of our platform:
					</Text>
					<ul>
						<li>
							<Text strong>Account information:</Text> This includes your name,
							email address, and username.
						</li>
						<li>
							<Text strong>Profile information:</Text> This may include your
							profile picture, bio, and other information you choose to make
							public.
						</li>
						<li>
							<Text strong>Usage information:</Text> This includes information
							about how you use our platform, such as the video chats you
							participate in, the educational tools you use, and the time you
							spend on the platform.
						</li>
						<li>
							<Text strong>Device information:</Text> This includes information
							about the device you are using to access the platform, such as
							your IP address, device type, and operating system.
						</li>
					</ul>
					<Text>
						For children under 13, we collect no information, seeing as no
						children are permitted to create accounts. When children under 13
						participate via an account made by their guardian or
						school/community center, we require parents or guardians to provide
						verified consent before said child uses our platform.
					</Text>
					<Title level={3}>How We Use and Disclose Information</Title>
					<Text>
						We use the information we collect from users of our platform to:
					</Text>
					<ul>
						<li>Provide and improve our platform.</li>
						<li>Deliver educational content and services.</li>
						<li>
							Communicate with users about their accounts and use of the
							platform.
						</li>
						<li>Conduct research and development.</li>
						<li>Detect and prevent fraud and abuse.</li>
					</ul>
					<Text>
						We may disclose personal information to third parties in the
						following limited circumstances:
					</Text>
					<ul>
						<li>To service providers who help us operate the platform.</li>
						<li>
							To comply with legal requirements or to respond to a subpoena or
							other legal process.
						</li>
						<li>
							To protect the rights, property, or safety of ourselves, our
							users, or the public.
						</li>
					</ul>
					<Text>
						We will not sell or rent your personal information to third parties
						for their marketing purposes.
					</Text>
					<Title level={3}>Special Provisions for Kids Under 13</Title>
					<Text>
						We take special precautions to protect the privacy of children under
						13 who use our platform. These precautions include:
					</Text>
					<ul>
						<li>
							<Text strong>
								Requiring verified parental consent before collecting any
								personally identifiable information from a child under 13.
							</Text>
						</li>
						<li>
							<Text strong>
								Ensuring children are never communicating with strangers on the
								platform.
							</Text>
						</li>
						<li>
							<Text strong>
								Providing parents or guardians with the ability to deny their
								child’s use of the platform.
							</Text>
						</li>
					</ul>
					<Text>
						We also use a variety of operational measures to protect the privacy
						of all children, especially those under 13. These can be found on
						our{" "}
						<a target="_blank" href="https://www.goodte.am/trust-and-safety">
							Trust & Safety Page
						</a>
						.
					</Text>
					<Title level={3}>Your Choices</Title>
					<Text>
						You have the following choices regarding your personal information:
					</Text>
					<ul>
						<li>
							<Text strong>
								You can review your personal information and make changes to
								your account settings at any time.
							</Text>
						</li>
						<li>
							<Text strong>
								You can request to delete your account and personal information
								at any time.
							</Text>
						</li>
						<li>
							<Text strong>
								You can opt out of receiving marketing emails from us (we
								currently do not send any).
							</Text>
						</li>
					</ul>
					<Text>
						To exercise any of these choices, please contact us at{" "}
						<a href="mailto:dpo@goodte.am">dpo@goodte.am</a>.
					</Text>
					<Title level={3}>Changes to This Privacy Policy</Title>
					<Text>
						We may update this privacy policy from time to time. If we make any
						material changes to this privacy policy, we will notify you by email
						or by posting a notice on our website.
					</Text>
					<Title level={3}>Contact Us</Title>
					<Text>
						If you have any questions about this privacy policy or our
						practices, please contact us at{" "}
						<a href="mailto:dpo@goodte.am">dpo@goodte.am</a>.
					</Text>
					<Title level={3}>Additional Information for Parents</Title>
					<Text>
						We encourage parents to talk to their children about online safety
						and privacy. Here are some tips:
					</Text>
					<ul>
						<li>
							<Text strong>
								Teach your children about the importance of keeping their
								personal information private. Explain that they should not share
								their name, address, phone number, or other personal information
								with strangers online.
							</Text>
						</li>
						<li>
							<Text strong>
								Encourage your children to report any suspicious activity to you
								immediately.
							</Text>
						</li>
						<li>
							<Text strong>
								Exercise your right to directly monitor your child's use of the
								platform during a live session.
							</Text>
						</li>
					</ul>
					<Text>
						We are committed to providing a safe and educational experience for
						all users of our platform. We appreciate your cooperation.
					</Text>
				</div>
			</div>
		</>
	);
};

export default Privacy;

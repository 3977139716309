import { useState, useEffect, useRef } from "react";
import { useMicrophoneAndCameraTracks } from "../../constants/settings.js";
import { Row, Col, notification, Space, Button, Alert } from "antd";
import Controls from "../controls/Controls";
import Video from "../video/Video";
import { useSelector } from "react-redux";
import {
	AGORA_APP_ID,
	SNAP_API_TOKEN,
	SNAP_LENS_GROUP_IDS,
} from "../../config.js";
import ToolsPanel from "../tools-panel/ToolsPanel.js";
import { bootstrapCameraKit, createMediaStreamSource } from "@snap/camera-kit";
import AgoraRTC from "agora-rtc-sdk-ng";
import VirtualBackgroundExtension from "agora-extension-virtual-background";
import { Link } from "react-router-dom";
import { AgoraVideoPlayer } from "agora-rtc-react";

export default function VideoCall(props) {
	const MAX_USER_COUNT = 4;
	const appContext = useSelector((state) => state.app);
	const [api, contextHolder] = notification.useNotification();
	const loggedUser = appContext.context.user;
	const { joinedRoom, agoraClient, userToJoin, socket } = props;
	const [users, setUsers] = useState([]);
	const [start, setStart] = useState(false);
	const [isBlurred, setIsBlurred] = useState(false);
	const [image, setImage] = useState(null);
	const [filter, setFilter] = useState(null);
	const [lenses, setLenses] = useState([]);
	const client = agoraClient();
	const { ready, tracks, error } = useMicrophoneAndCameraTracks();
	const [uniqueUsers, setUniqueUsers] = useState([]);
	const uniqueUsersRef = useRef([]);
	const usersRef = useRef([]);
	const imageRef = useRef(null);
	const currentUserCountRef = useRef(1);
	const waitingUsersIdsRef = useRef([]);
	const [processor, setProcessor] = useState(null);
	const [session, setSession] = useState(null);
	const [blurLens, setBlurLens] = useState(null);

	const [hasTimer, setHasTimer] = useState(false);
	const [timer, setTimer] = useState(300);
	const [timerRunning, setTimerRunning] = useState(false);

	const hasTimerRef = useRef(false);
	const timerRef = useRef(300);
	const timerRunningRef = useRef(false);

	const extension = new VirtualBackgroundExtension();
	AgoraRTC.registerExtensions([extension]);

	// Initialization
	async function getProcessorInstance() {
		if (!processor && tracks[1]) {
			let newP = null;
			newP = extension.createProcessor();
			try {
				await newP.init("./assets/wasms");
			} catch (e) {
				console.error("Fail to load WASM resource!");
				return null;
			}

			tracks[1].pipe(newP).pipe(tracks[1].processorDestination);
			setProcessor(newP);
		}
	}

	const onNotificationBtnClick = (status, key) => {
		client.sendStreamMessage(`NOTIFICATION ${key}`);
		api.destroy(key);
		// Take out admitted user
		waitingUsersIdsRef.current = waitingUsersIdsRef.current.filter(
			(u) => u.uid !== key
		);
		if (status === "admit") {
			currentUserCountRef.current = currentUserCountRef.current + 1;
			if (currentUserCountRef.current >= MAX_USER_COUNT) {
				for (
					let index = 0;
					index < waitingUsersIdsRef.current.length;
					index++
				) {
					const user = waitingUsersIdsRef.current[index];
					api.open({
						placement: "top",
						message: `${user.fullName} is in the waiting room. One person must leave in order to admit.`,
						btn: (
							<Space>
								<Button
									type="link"
									size="small"
									onClick={() => onNotificationBtnClick("deny", user.uid)}
								>
									Deny
								</Button>
								<Button disabled={true} type="primary" size="small">
									Admit
								</Button>
							</Space>
						),
						duration: 0,
						key: user.uid,
					});
				}
			}
			// if (currentUserCountRef.current >= MAX_USER_COUNT) {
			// 	api.open({
			// 		placement: "top",
			// 		type: "warning",
			// 		message: "Maximum of 4 users are allowed in one room at once.",
			// 		key: -1,
			// 	});
			// 	socket.emit("change_status", {
			// 		room: joinedRoom.room.link,
			// 		status: "room_full",
			// 		uid: key,
			// 	});
			// 	return;
			// }
		}
		socket.emit("change_status", {
			room: joinedRoom.room.link,
			status,
			uid: key,
		});
	};

	useEffect(() => {
		uniqueUsersRef.current = uniqueUsers;
	}, [uniqueUsers]);

	useEffect(() => {
		usersRef.current = users;
	}, [users]);

	useEffect(() => {
		if (!processor && !joinedRoom.isUserHost) return;
		if (isBlurred) {
			if (joinedRoom.isUserHost && !filter) {
				setFilter(blurLens);
			} else {
				processor.setOptions({ type: "blur", blurDegree: 2 });
				processor.enable();
			}
		} else {
			if (joinedRoom.isUserHost) {
				session?.removeLens();
				setFilter(null);
			} else {
				processor.disable();
			}
		}
	}, [isBlurred]);

	useEffect(() => {
		imageRef.current = image;
		if (session) {
			if (isBlurred && !filter) {
				session.applyLens(blurLens);
			} else {
				filter ? session.applyLens(filter) : session.removeLens();
			}
		}
		if (!image) {
			socket.emit("signal_canvas_update", {
				room: joinedRoom.room.link,
				imageData: null,
				uid: userToJoin.uid,
			});
		}

		if (ready) {
			const data = image ? image : "no";
			client.sendStreamMessage(data);
		}
	}, [image, filter]);

	useEffect(() => {
		const data = [hasTimer, timer, timerRunning];
		if (ready && joinedRoom.isUserHost) {
			client.sendStreamMessage(`TIMER ${data.join(",")}`);
		}
		hasTimerRef.current = hasTimer;
		timerRef.current = timer;
		timerRunningRef.current = timerRunning;
	}, [hasTimer, timerRunning]);

	useEffect(() => {
		// checking if 5 minutes were added, then signal it to other users
		if (timer - timerRef.current > 290) {
			const data = [hasTimer, timer, timerRunning];
			if (ready && joinedRoom.isUserHost) {
				try {
					client.sendStreamMessage(`TIMER ${data.join(",")}`);
				} catch (error) {}
			}
		}
		timerRef.current = timer;
	}, [timer]);

	useEffect(() => {
		socket.emit("join_room", joinedRoom.room.link);
		socket.on("notify_host", (user) => {
			// if (usersRef.current.length + 1 === MAX_USER_COUNT) {
			// 	socket.emit("change_status", {
			// 		room: joinedRoom.room.link,
			// 		status: "room_full",
			// 		uid: user.uid,
			// 	});

			// 	api.open({
			// 		placement: "top",
			// 		message: `${user.fullName} is in the waiting room. Someone must leave in order for them to be admitted.`,
			// 		duration: 7,
			// 		key: user.uid,
			// 	});
			// 	return;
			// }
			if (joinedRoom.isUserHost) {
				waitingUsersIdsRef.current.push(user);
				api.open({
					placement: "top",
					message: `${user.fullName} is in the waiting room. ${
						currentUserCountRef.current >= MAX_USER_COUNT
							? "One person must leave in order to admit."
							: ""
					}`,
					btn: (
						<Space>
							<Button
								type="link"
								size="small"
								onClick={() => onNotificationBtnClick("deny", user.uid)}
							>
								Deny
							</Button>
							<Button
								disabled={currentUserCountRef.current >= MAX_USER_COUNT}
								type="primary"
								size="small"
								onClick={() => onNotificationBtnClick("admit", user.uid)}
							>
								Admit
							</Button>
						</Space>
					),
					duration: 0,
					key: user.uid,
				});
			}
		});
	}, [socket]);

	useEffect(() => {
		let init = async (name) => {
			client.on("user-published", async (user, mediaType) => {
				await client.subscribe(user, mediaType);
				if (mediaType === "video") {
					setUsers((prevUsers) => {
						currentUserCountRef.current = prevUsers.length + 2;
						return [...prevUsers, user];
					});
				}
				if (mediaType === "audio") {
					user.audioTrack.play();
				}

				setUniqueUsers((prevUsers) => {
					const userObj = JSON.parse(user.uid);
					const exists = prevUsers.some((u) => u.user.uid === userObj.uid);
					return exists
						? prevUsers
						: [...prevUsers, { user: userObj, imageUrl: null }];
				});
			});

			client.on("user-unpublished", (user, mediaType) => {
				if (mediaType === "audio") {
					if (user.audioTrack) user.audioTrack.stop();
				}
				if (mediaType === "video") {
					setUsers((prevUsers) => {
						const users = prevUsers.filter((User) => User.uid !== user.uid);
						return users;
					});
				}
			});

			client.on("user-left", (user) => {
				setUsers((prevUsers) => {
					const users = prevUsers.filter((User) => User.uid !== user.uid);
					currentUserCountRef.current = users.length + 1;

					if (currentUserCountRef.current < MAX_USER_COUNT) {
						for (
							let index = 0;
							index < waitingUsersIdsRef.current.length;
							index++
						) {
							const user = waitingUsersIdsRef.current[index];
							api.open({
								placement: "top",
								message: `${user.fullName} is in the waiting room.`,
								btn: (
									<Space>
										<Button
											type="link"
											size="small"
											onClick={() => onNotificationBtnClick("deny", user.uid)}
										>
											Deny
										</Button>
										<Button
											type="primary"
											size="small"
											onClick={() => onNotificationBtnClick("admit", user.uid)}
										>
											Admit
										</Button>
									</Space>
								),
								duration: 0,
								key: user.uid,
							});
						}
					}

					return users;
				});

				const userObj = JSON.parse(user.uid);
				waitingUsersIdsRef.current = waitingUsersIdsRef.current.filter(
					(u) => u.uid !== userObj.uid
				);
			});

			client.on("user-joined", (user) => {
				const data = imageRef.current ? imageRef.current : "no";
				client.sendStreamMessage(data);
				const timerData = [
					hasTimerRef.current,
					timerRef.current,
					timerRunningRef.current,
				];
				if (joinedRoom.isUserHost) {
					client.sendStreamMessage(`TIMER ${timerData.join(",")}`);
				}
			});

			// Listen for stream messages.
			// For now only used to receive a signal to handle "End for all" event.
			client.on("stream-message", async (user, msg) => {
				const receivedMessage = String.fromCharCode(...msg);
				if (receivedMessage === "END") {
					await client.leave();
					client.removeAllListeners();
					tracks[0].close();
					tracks[1].close();
					setStart(false);
					window.location.href = "/";
				} else if (receivedMessage.includes("TIMER")) {
					const timerData = receivedMessage.replace("TIMER ", "").split(",");
					setHasTimer(timerData[0] === "true");
					setTimer(Number(timerData[1]));
					setTimerRunning(timerData[2] === "true");
				} else if (receivedMessage.includes("NOTIFICATION")) {
					// dismiss notification
					const key = receivedMessage.split(" ")[1];
					api.destroy(key);
				} else {
					// if (uid === userToJoin.uid) return;
					const userObj = JSON.parse(user);
					const { uid } = userObj;
					const snapImgId = `user-image-${uid}`;
					const agoraVideoId = `user-agora-${uid}`;
					const divEl = document.getElementById(snapImgId);
					const agoraEl = document.getElementById(agoraVideoId);
					if (agoraEl) {
						if (receivedMessage === "no") {
							agoraEl.style.display = "flex";
							divEl.style.display = "none";
						} else {
							const imgEl = divEl.querySelector("img");
							divEl.style.display = "flex";
							imgEl.src = receivedMessage;
							agoraEl.style.display = "none";
						}
					}
				}
			});

			try {
				await client.join(
					AGORA_APP_ID,
					name,
					joinedRoom.token,
					JSON.stringify(userToJoin)
				);
			} catch (error) {
				console.log("error");
			}

			if (tracks) {
				await getProcessorInstance();
				const canvasContainer = document.getElementById("canvas-container");

				// Only when host, load snap
				if (joinedRoom.isUserHost) {
					const cameraKit = await bootstrapCameraKit({
						apiToken: SNAP_API_TOKEN,
					});

					const session = await cameraKit.createSession({
						liveRenderTarget: canvasContainer,
					});

					const modifiedMediaStream = new MediaStream();
					modifiedMediaStream.addTrack(tracks[1].getMediaStreamTrack());
					session.setSource(modifiedMediaStream);

					const source = createMediaStreamSource(modifiedMediaStream);

					const customSource = AgoraRTC.createCustomVideoTrack({
						mediaStreamTrack: session.output.live
							.captureStream()
							.getVideoTracks()[0],
					});

					await session.setSource(source);

					session.play();

					setSession(session);

					await client.publish([tracks[0], customSource]);

					let { lenses } = await cameraKit.lensRepository.loadLensGroups(
						SNAP_LENS_GROUP_IDS
					);

					const blur = lenses.find((l) => l.name === "Background Blur");
					setBlurLens(blur);
					lenses = lenses.filter((l) => l.name !== "Background Blur");

					lenses.sort((a, b) => {
						const nameA = a.name.toUpperCase();
						const nameB = b.name.toUpperCase();
						if (nameA < nameB) {
							return -1;
						}
						if (nameA > nameB) {
							return 1;
						}
						return 0;
					});

					setLenses(lenses);
				} else {
					const videoElement = document.createElement("video");
					canvasContainer.parentNode.insertBefore(
						videoElement,
						canvasContainer
					);
					videoElement.style.width = "100%";
					videoElement.style.height = "100%";
					tracks[1].play(videoElement);
					await client.publish([tracks[0], tracks[1]]);
				}
			}
			setStart(true);
		};

		if (ready && tracks) {
			try {
				init(joinedRoom.room.link);
				if (joinedRoom.isUserHost) {
					socket.emit("host_joined", joinedRoom.room.link);
				}
			} catch (error) {
				console.log(error);
			}
		}
	}, [joinedRoom.room.link, client, ready, tracks]);

	return (
		<Row justify="center" align="middle" style={{ height: "100%" }}>
			{contextHolder}
			<Col span={18}>
				<Col
					span={24}
					style={{
						display: "flex",
						justifyContent: "center",
						height: "87vh",
						padding: "20px 20px 0px 20px",
					}}
				>
					{!error ? (
						<Video
							socket={socket}
							image={image}
							filter={filter}
							tracks={tracks}
							users={users}
							uniqueUsers={uniqueUsers}
							userToJoin={userToJoin}
							joinedRoom={joinedRoom}
						/>
					) : (
						<>
							{error.code === "PERMISSION_DENIED" ? (
								<Alert
									showIcon
									message="Hey. Looks like we don't have access to your camera. Enable
										camera access for interactive sessions. We value your
										privacy and only use the camera for teaching purposes."
									type="warning"
									style={{ height: "100px" }}
								/>
							) : (
								<>
									<h3>Could not join due to following error:</h3>
									<p>{JSON.stringify(error)}</p>
									<Link to="/">Return to main page</Link>{" "}
								</>
							)}
						</>
					)}
				</Col>
				<Col
					span={24}
					style={{
						height: "13vh",
						padding: "25px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					{ready && tracks && (
						<Controls
							agoraClient={agoraClient}
							joinedRoom={joinedRoom}
							loggedUser={loggedUser}
							tracks={tracks}
							isBlurred={isBlurred}
							hasTimer={hasTimer}
							setHasTimer={setHasTimer}
							timer={timer}
							setTimer={setTimer}
							timerRunning={timerRunning}
							setTimerRunning={setTimerRunning}
							setStart={setStart}
							setIsBlurred={setIsBlurred}
						/>
					)}
				</Col>
			</Col>
			{ready && tracks && (users.length > 3 || joinedRoom.isUserHost) && (
				<Col style={{ height: "100vh" }} span={6}>
					<div
						style={{
							zIndex: users.length > 3 ? "1" : "-1",
							width: "100%",
							height: "95%",
							background: users.length > 3 ? "#8e8e8e5c" : "",
							position: "absolute",
							top: "10px",
						}}
					>
						{users.length > 0 &&
							users
								.filter((user) => user.videoTrack)
								.slice(3)
								.map((user, index) => {
									const userObj = JSON.parse(user.uid);
									const u = uniqueUsers.find((u) => u.user.uid === userObj.uid);
									const imageId = `user-image-${u.user.uid}`;
									const agoraId = `user-agora-${u.user.uid}`;
									if (user.videoTrack) {
										return (
											<Col style={{ height: "25%" }} span={24} key={user.uid}>
												<div
													id={imageId}
													style={{
														display: "none",
														height: "100%",
														justifyContent: "center",
														marginTop: index > 0 ? "12px" : "0",
													}}
												>
													<img
														style={{
															height: "100%",
															width: "100%",
															objectFit: "cover",
														}}
													></img>
												</div>
												<AgoraVideoPlayer
													id={agoraId}
													videoTrack={user.videoTrack}
													style={{
														height: "100%",
														width: "100%",
														display: "block",
														marginTop: index > 0 ? "12px" : "0",
													}}
												/>
												<div
													style={{
														position: "absolute",
														bottom: "0",
														background: "rgba(0,0,0,0.5)",
														color: "#fff",
														padding: "4px 8px",
													}}
												>
													{userObj.fullName}
												</div>
											</Col>
										);
									} else return null;
								})}
					</div>
					{joinedRoom.isUserHost && (
						<ToolsPanel
							room={joinedRoom.room}
							lenses={lenses}
							setFilter={setFilter}
							setImage={setImage}
						/>
					)}
				</Col>
			)}
		</Row>
	);
}

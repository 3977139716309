import React, { useEffect, useState, useRef } from "react";
import ToolsPanelSCSS from "./ToolsPanel.module.scss";
import { Avatar, Button, Col, Input, Row, Select, Empty, Spin } from "antd";
import {
	SearchOutlined,
	CaretLeftOutlined,
	CaretRightOutlined,
	EyeOutlined,
	ClockCircleOutlined,
	StopOutlined,
} from "@ant-design/icons";
import { useDispatch } from "../../store";
import { useSelector } from "react-redux";
import {
	getAllCategories,
	getListItems,
	getLists,
	getSubcategories,
} from "../../store/listItems";

const ToolsPanel = (props) => {
	const dispatch = useDispatch();
	const appContext = useSelector((state) => state.app);
	const { categories, subcategories, lists } = useSelector(
		(state) => state.listItems
	);
	const { accessToken } = appContext.context;
	const { setImage, setFilter, lenses, room } = props;
	const [selectedFilter, setSelectedFilter] = useState("");
	const [selectedItem, setSelectedItem] = useState("");
	const [selectedItemIndex, setSelectedItemIndex] = useState(0);
	const [primaryButtonIndex, setPrimaryButtonIndex] = useState(-1);
	const [searchText, setSearchText] = useState("");
	const [isSubcategoryEnabled, setIsSubcategoryEnabled] = useState(false);
	const [isListEnabled, setIsListEnabled] = useState(false);
	const [selectedSubcategory, setSelectedSubcategory] = useState(null);
	const [selectedList, setSelectedList] = useState(null);
	const [listItems, setListItems] = useState([]);
	const [clickCount, setClickCount] = useState(0);
	const [categoryId, setCategoryId] = useState(0);
	const [subcategoryId, setSubategoryId] = useState(0);
	const [clickTimer, setClickTimer] = useState(null);
	const listContainerRef = useRef(null);

	useEffect(() => {
		dispatch(getAllCategories({ accessToken }));
	}, []);

	useEffect(() => {
		setFilter(null);
		setSelectedFilter(null);
	}, [searchText]);

	const filteredLenses = lenses.filter((lens) => {
		return (
			lens.name.toLowerCase().includes(searchText.toLowerCase()) ||
			lens.vendorData?.tags?.toLowerCase().includes(searchText.toLowerCase())
		);
	});

	const handleFilterClick = (filter) => {
		if (filter.id === selectedFilter) {
			setFilter(null);
			setSelectedFilter(null);
			return;
		}
		setPrimaryButtonIndex(-1);
		setImage(null);
		setSelectedFilter(filter.id);
		setFilter(filter);
	};

	const handleItemClick = (countToMove) => {
		if (clickCount === 0) {
			setClickCount(1);
			setClickTimer(
				setTimeout(() => {
					singleClick(countToMove);
					setClickCount(0);
				}, 300)
			);
		} else if (clickCount === 1) {
			clearTimeout(clickTimer);
			setClickCount(0);
			handleDoubleClick(countToMove);
		}
	};

	const singleClick = (countToMove) => {
		setFilter(null);
		setSelectedFilter(null);
		if (primaryButtonIndex !== selectedItemIndex) {
			if (countToMove === -1) {
				// Don't move above list items
				if (selectedItemIndex + countToMove < 0) {
					setPrimaryButtonIndex(-1);
					return;
				}
				setSelectedItemIndex((prevCount) => prevCount - 1);
				setPrimaryButtonIndex(selectedItemIndex - 1);
				setImage(listItems[selectedItemIndex - 1].imageUrl);
			} else {
				setPrimaryButtonIndex(selectedItemIndex);
				setImage(listItems[selectedItemIndex].imageUrl);
			}
		} else if (primaryButtonIndex === selectedItemIndex) {
			setImage(null);
			if (countToMove === -1) {
				setPrimaryButtonIndex(-1);
			} else {
				// Trying to move forward at last element
				if (selectedItemIndex + countToMove === listItems.length) {
					return;
				}
				setPrimaryButtonIndex(-1);
				setSelectedItemIndex((prevCount) => prevCount + 1);
			}
		}
	};

	const handleDoubleClick = (countToMove) => {
		setFilter(null);
		setSelectedFilter(null);

		if (countToMove === 1) {
			if (selectedItemIndex + countToMove === listItems.length) {
				return;
			}
			if (primaryButtonIndex === -1) {
				setPrimaryButtonIndex(selectedItemIndex);
				setImage(listItems[selectedItemIndex].imageUrl);
			} else {
				setSelectedItemIndex((prevCount) => prevCount + 1);
				setPrimaryButtonIndex(selectedItemIndex + 1);
				setImage(listItems[selectedItemIndex + 1].imageUrl);
			}
		} else {
			if (primaryButtonIndex !== -1) {
				// Don't move above list items
				if (selectedItemIndex + countToMove < 0) {
					setPrimaryButtonIndex(-1);
					return;
				}
				setSelectedItemIndex((prevCount) => prevCount - 1);
				setPrimaryButtonIndex(selectedItemIndex - 1);
				setImage(listItems[selectedItemIndex - 1].imageUrl);
			}
		}
	};

	useEffect(() => {
		if (listContainerRef.current && selectedItemIndex !== undefined) {
			const selectedListItem =
				listContainerRef.current.children[selectedItemIndex];
			if (selectedListItem) {
				selectedListItem.scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
			}
		}
	}, [selectedItemIndex]);

	const handleCategoryChange = async (id) => {
		setCategoryId(id);
		dispatch(getSubcategories({ accessToken, id }));
		setIsSubcategoryEnabled(true);
		setIsListEnabled(false);
		setSelectedSubcategory(null);
		setSelectedList(null);
		setListItems([]);
	};

	const handleSubcategoryChange = async (id) => {
		setSubategoryId(id);
		// Random 15
		if (id === -1) {
			setSelectedItem("true");
			resetItems();
			const res = await dispatch(
				getListItems({ accessToken, id, categoryId, id })
			).unwrap();
			setListItems(res.listItems);
		} else {
			dispatch(getLists({ accessToken, id }));
			setListItems([]);
		}
		setSelectedSubcategory(id);
		setIsListEnabled(true);
		setSelectedList(null);
	};

	const handleListChange = async (id) => {
		setSelectedList(id);
		setSelectedItem("true");
		resetItems();
		const res = await dispatch(
			getListItems({ accessToken, id, categoryId, subcategoryId })
		).unwrap();
		setListItems(res.listItems);
	};

	const resetItems = () => {
		setPrimaryButtonIndex(-1);
		setSelectedItemIndex(0);
		setImage(null);
	};

	return (
		<div className={ToolsPanelSCSS.container}>
			<Input
				suffix={<SearchOutlined />}
				allowClear
				className={ToolsPanelSCSS.searchBox}
				placeholder="Search filters..."
				value={searchText}
				onChange={(e) => setSearchText(e.target.value)}
			/>
			<div className={ToolsPanelSCSS.filterContainer}>
				{lenses.length === 0 ? (
					<div className={ToolsPanelSCSS.loadingContainer}>
						<Spin />
					</div>
				) : (
					filteredLenses.map((filter, index) => (
						<div
							key={index}
							className={`${ToolsPanelSCSS.filterCard} ${
								selectedFilter === filter.id ? ToolsPanelSCSS.selected : ""
							}`}
							onClick={() => handleFilterClick(filter)}
						>
							<div className={ToolsPanelSCSS.imageAndName}>
								{/* <Avatar size={35} src={filter.iconUrl} alt="avatar" /> */}
								<p>{filter.name}</p>
							</div>
							{selectedFilter === filter.id && "✕"}
						</div>
					))
				)}
			</div>
			<div className={ToolsPanelSCSS.listContainer}>
				<Row gutter={15}>
					<Col
						style={{ display: "flex", flexDirection: "column", gap: "8px" }}
						span={13}
					>
						<Select
							placeholder="Category"
							onChange={handleCategoryChange}
							style={{ width: "100%" }}
							options={categories
								.filter((c) => room.categories.includes(c.name))
								.map((c) => {
									return { label: c.name, value: c.id };
								})}
						/>
						<Select
							disabled={!isSubcategoryEnabled}
							placeholder="Subcategory"
							onChange={handleSubcategoryChange}
							value={selectedSubcategory}
							style={{ width: "100%" }}
							options={subcategories.map((c) => {
								return { label: c.name, value: c.id };
							})}
						/>
						<Select
							disabled={!isListEnabled || subcategoryId === -1}
							placeholder="List"
							onChange={handleListChange}
							value={selectedList}
							style={{ width: "100%" }}
							options={lists.map((c) => {
								return { label: c.name, value: c.id };
							})}
						/>
					</Col>
					<Col span={11}>
						<Button
							disabled={listItems.length === 0}
							onClick={() => handleItemClick(-1)}
							style={{
								height: "100%",
								width: "50%",
								borderRightWidth: "1px",
								borderTopRightRadius: "0",
								borderBottomRightRadius: "0",
							}}
							icon={<CaretLeftOutlined style={{ fontSize: "40px" }} />}
						/>
						<Button
							disabled={listItems.length === 0}
							onClick={() => handleItemClick(1)}
							style={{
								height: "100%",
								width: "50%",
								borderLeftWidth: "1px",
								borderTopLeftRadius: "0",
								borderBottomLeftRadius: "0",
							}}
							icon={<CaretRightOutlined style={{ fontSize: "40px" }} />}
						/>
					</Col>
				</Row>
				<div ref={listContainerRef} className={ToolsPanelSCSS.listItems}>
					{listItems.length > 0 ? (
						listItems.map((item, index) => (
							<div
								key={index}
								className={`${ToolsPanelSCSS.listItemCard} ${
									selectedItemIndex === index ? ToolsPanelSCSS.selected : ""
								}`}
								onClick={() => {
									// setSelectedItemIndex(index);
									// setPrimaryButtonIndex(-1);
									// setImage(null);

									if (primaryButtonIndex !== index) {
										setSelectedItemIndex(index);
										setPrimaryButtonIndex(index);
										setImage(item.imageUrl);
									} else {
										setSelectedItemIndex(index + 1);
										setPrimaryButtonIndex(-1);
										setImage(null);
									}
								}}
							>
								<div className={ToolsPanelSCSS.itemContainer}>
									<Avatar size={35} src={item.iconUrl} alt="avatar" />
									<p>{item.name.replace(/_/g, "/")}</p>
								</div>
								<div style={{ display: "flex", alignItems: "center", gap: 10 }}>
									<Button
										type={
											primaryButtonIndex !== index &&
											selectedItemIndex === index
												? "primary"
												: ""
										}
										icon={<ClockCircleOutlined />}
										onClick={(e) => {
											e.stopPropagation();
											setSelectedItemIndex(index);
											setPrimaryButtonIndex(-1);
											setImage(null);
										}}
									/>
									<Button

										type={primaryButtonIndex === index ? "primary" : ""}
										icon={<EyeOutlined />}
										onClick={(e) => {
											e.stopPropagation();
											if (primaryButtonIndex !== index) {
												setSelectedItemIndex(index);
												setPrimaryButtonIndex(index);
												setImage(item.imageUrl);
											} else {
												setSelectedItemIndex(index + 1);
												setPrimaryButtonIndex(-1);
												setImage(null);
											}
										}}
									/>
								</div>
							</div>
						))
					) : (
						<div className={ToolsPanelSCSS.empty}>
							<Empty
								description={"Select all dropdowns"}
								image={Empty.PRESENTED_IMAGE_SIMPLE}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ToolsPanel;
